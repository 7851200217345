import React from 'react';
import { navigate } from 'gatsby-link';
import Layout from '../../components/Layout';
import Jumbotron from '../../components/Jumbotron';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout>
        <Jumbotron
          jumbotron={{
            image: '/img/sergey-zolkin-m9qMoh-scfE-unsplash.jpg',
            heading: 'Contact Us!',
          }}
        />
        <section className="section">
          <div className="container">
            <h1 className="title is-size-3 has-text-weight-bold is-bold-light">
              Contact
            </h1>
            <p>
              <a href="https://goo.gl/maps/VDFU1cHGXH5JZc7F7">
                594/30 Hathai Rat Road, Bangchan, Khongsamwa, Bangkok, 10510
              </a>
            </p>
            <p>
              Tel & Fax (Office):{' '}
              <a href="tel:006626395636">+66 (0) 2010 6144</a>
            </p>
            <br />
            {/* Contact Card */}
            <h2 className="title is-size-5 has-text-weight-bold is-bold-light">
              Mr. Preechapon Wannakrairot
            </h2>
            <h3>Sales Manager</h3>
            <p>
              Email:{' '}
              <a href="mailto:preechapon.w@topenergy.co.th">
                preechapon.w@topenergy.co.th
              </a>
            </p>
            <p>
              Mobile: <a href="tel:0066926395636">+66 (0) 92 639 5636</a>
            </p>
          </div>
        </section>
      </Layout>
    );
  }
}
